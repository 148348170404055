import { toast } from 'react-toastify';

const toastMessage = {
  showSuccess: (message) => {
    toast.success(message || 'Action successful!');
  },
  showError: (message) => {
    toast.error(message || 'An error occurred!');
  },
};

export default toastMessage;

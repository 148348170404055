import { Box, Container } from '@mui/material';
import MainTitle from '../components/partials/MainTitle';
import { Outlet } from 'react-router-dom';
import { useModal } from '../hooks/useModal';
import CreateNewBotModal from '../containers/list-bot/create-new-bot/CreateNewBotModal';

const ListBot = () => {
  const createBotModal = useModal('create-bot', CreateNewBotModal, {
    configs: {
      size: 'sm',
    },
  });

  const openCreateBotModal = () => {
    createBotModal.openModal();
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 3 }}>
        <MainTitle
          title="Danh sách Bot"
          buttonName={'Tạo Bot mới'}
          onClick={() => {
            openCreateBotModal();
          }}
        />
        <Box>
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};

export default ListBot;

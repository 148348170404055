import axios from 'axios';
import authToken from './authToken';
import toastMessage from './toastMessage';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const httpRequest = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

httpRequest.interceptors.response.use(
  (response) => {
    const method = response.config.method.toLocaleLowerCase();
    if (['post', 'put', 'delete'].includes(method)) {
      toastMessage.showSuccess();
    }
    return response;
  },
  (error) => {
    // toastMessage.showError();
    return Promise.reject(error);
  }
);

export const setHeadersAuthorization = () => {
  // const token = authToken.getTokenFromLocalStorage();
  // if (token) {
  //   httpRequest.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // }
};

export default httpRequest;

import { Box, Card, CardContent, CardHeader, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useModal } from '../../../hooks/useModal';
import CreateNewBot from './CreateNewBot';
const CreateNewBotModal = () => {
  const createBotModal = useModal('create-bot');

  const handleCloseModal = (data) => {
    createBotModal.closeModal(data || {});
  };

  return (
    <Box>
      <Card>
        <CardHeader
          sx={{ '&.MuiCardHeader-root': { borderBottom: '1px solid #ccc', py: 2 } }}
          action={
            <IconButton onClick={handleCloseModal} aria-label="settings">
              <CloseIcon />
            </IconButton>
          }
          title="Create new Bot"
        />
        <CardContent>
          <CreateNewBot onClose={handleCloseModal} />
        </CardContent>
      </Card>
    </Box>
  );
};

export default CreateNewBotModal;

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../reducers/slices/authSlice';
import authService from '../../../services/authService';

const HeaderUserMenu = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.auth);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [userLogged, setUserLogged] = useState(authSelector.user);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goToAccount = () => {
    navigate('/account');
    handleCloseUserMenu();
  };

  const handleLogout = async () => {
    handleCloseUserMenu();
    dispatch(logout());
    await authService.logout();
    navigate('/auth');
  };

  return (
    <>
      {/* Avatar */}
      <Tooltip title="Tài khoản">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar sizes="small" src={userLogged?.avatar} />
        </IconButton>
      </Tooltip>

      {/* Menu */}
      <Menu
        sx={{ mt: '45px', '& .MuiList-root': { padding: 1.5 } }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem onClick={goToAccount} sx={{ minWidth: '240px', borderRadius: 2, py: 1.5 }}>
          <Box display={'flex'} flexDirection={'row'}>
            <Avatar sizes="small" src={userLogged?.avatar} />
            <Box sx={{ ml: 1 }} display={'flex'} flexDirection={'column'}>
              <Typography variant="caption" color="text.secondary">
                Tài khoản
              </Typography>
              <Typography variant="body1" fontSize={16} fontWeight={500} mt={-0.5}>
                {userLogged?.name}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleLogout}
          sx={{
            borderRadius: 2,
            py: 1.5,
            '&:hover': {
              color: theme.palette.error.main,
            },
            color: theme.palette.error.light,
          }}
        >
          <ListItemText primary="Đăng xuất" />
          <LogoutOutlinedIcon sx={{ ml: 1 }} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderUserMenu;

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormLabel,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import VisuallyHiddenInput from '../../../components/forms/VisuallyHiddenInput';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import BotFileItem from './BotFileItem';
import botService from '../../../services/botService';
import uploadService from '../../../services/uploadService';

const BotFileTranning = () => {
  const theme = useTheme();
  const botSelector = useSelector((state) => state.bot);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Đang tiến hành quét, vui lòng chờ...');
  const [bot, setBot] = useState(botSelector.currentBot);
  const [addedFiles, setAddedFiles] = useState(bot.files || []);
  const [uploadFiles, setUploadFiles] = useState([]);

  useEffect(() => {
    if (botSelector.currentBot?.id) {
      setBot(botSelector.currentBot);
      setAddedFiles(botSelector.currentBot.files || []);
    }
  }, [botSelector.currentBot]);

  const handleRemoveAddedFile = (file) => {
    const newAddedFiles = addedFiles.filter((addedFile) => addedFile.id !== file.id);
    setAddedFiles(newAddedFiles);
  };

  const handleRemoveUploadFile = (file) => {
    const newUploadFiles = uploadFiles.filter((uploadFile) => uploadFile !== file);
    setUploadFiles(newUploadFiles);
  };

  const updateBot = (body) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setLoadingText('Đang tiến hành tranning bot, vui lòng chờ...');
    botService
      .tranningFiles(bot.id, body)
      .then((res) => {
        setBot(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setLoadingText('Đang tiến hành quét file, vui này chờ...');
    const fileIds = [];
    if (!uploadFiles.length) {
      fileIds.push(...addedFiles.map((file) => file.id));
      updateBot({ fileIds: fileIds });
    } else {
      uploadService
        .uploadFiles(uploadFiles)
        .then((res) => {
          setIsLoading(false);
          fileIds.push(...addedFiles.map((file) => file.id));
          fileIds.push(...res.map((file) => file.id));
          updateBot({ fileIds: fileIds });
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <Card>
      <CardContent>
        <CardContentHeader title="Training với file" />

        <FormLabel>Danh sách file</FormLabel>
        <Box mb={3} mt={1}>
          <Grid container spacing={2}>
            {addedFiles.map((file, index) => (
              <Grid key={index} xl={3} lg={4} md={4} xs={6} sm={6} item>
                <Link target="_blank" to={file.url}>
                  <BotFileItem file={file} handleDelete={handleRemoveAddedFile} />
                </Link>
              </Grid>
            ))}
            {uploadFiles.map((file, index) => (
              <Grid key={index} xl={3} lg={4} md={4} xs={6} sm={6} item>
                <BotFileItem file={file} handleDelete={handleRemoveUploadFile} />
              </Grid>
            ))}
            <Grid item xl={3} lg={4} md={4} xs={6} sm={6}>
              <Tooltip title="Tải file lên" placement="right">
                <Button
                  disabled={isLoading}
                  component="label"
                  sx={{
                    borderRadius: theme.shape.borderRadius,
                    width: '100%',
                    height: 100,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: `1px dashed ${theme.palette.primary.main}`,
                  }}
                >
                  <DriveFolderUploadOutlinedIcon />
                  <VisuallyHiddenInput
                    accept=".txt,.docx,.pdf,.xlsx"
                    type="file"
                    onChange={(e) => setUploadFiles([...uploadFiles, ...e.target.files])}
                  />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        {isLoading && (
          <Typography variant="caption" sx={{ mt: 1, fontSize: theme.typography.fontSize }}>
            <CircularProgress
              sx={{ mr: 1 }}
              color="inherit"
              size={12}
              thickness={5}
              variant="indeterminate"
            />
            {loadingText}
          </Typography>
        )}
        <Box width={'100%'} display={'flex'} justifyContent={'end'}>
          <Button onClick={handleSubmit} type="submit" variant="contained" disabled={isLoading}>
            {!isLoading ? (
              'Bắt đầu tải file lên'
            ) : (
              <span>
                Đang tải file lên
                <CircularProgress
                  sx={{ ml: 1 }}
                  color="inherit"
                  size={12}
                  thickness={5}
                  variant="indeterminate"
                />
              </span>
            )}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BotFileTranning;

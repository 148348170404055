import httpRequest from '../utils/httpRequest';
const END_POINT = '/users';

const userService = {
  getUsageLimit: (idOwner) => {
    return httpRequest.get(`${END_POINT}/usage-limit`, {
      params: { idUser : idOwner }
    }).then((res) => res.data);
  },

  getUsageItroduction: () => {
    return httpRequest.get(`${END_POINT}/introduction-code`).then((res) => res.data);
  },

  updateInformation: (data) => {
    return httpRequest.put(`${END_POINT}/${data.id}`, data).then((res) => res.data);
  },

  deleteUser: (id) => {
    return httpRequest.delete(`${END_POINT}/${id}`).then((res) => res.data);
  },
  createUser: (data) => {
    return httpRequest.post(`${END_POINT}`, data).then((res) => res.data);
  }
};

export default userService;

import {
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';

const sidebarWidth = 320;

const Sidebar = ({ title, pages }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const isActivePage = (page) => {
    if (!page.href) {
      return false;
    }
    return location.pathname.includes(page.href);
  };

  const goToPage = (page) => {
    if (!page.href || isActivePage(page)) {
      return;
    }
    navigate(`${id}${page.href}`);
  };

  return (
    <Box width={sidebarWidth}>
      <Drawer variant="permanent" open>
        <Toolbar />
        <Box width={sidebarWidth} p={3} boxSizing={'border-box'} border={'none'}>
          <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
            {title || 'Settings'}
          </Typography>
          <List>
            {pages?.length &&
              pages.map((page) => (
                <ListItem key={page.title} sx={{ px: 0, py: 0.5 }}>
                  <ListItemButton
                    onClick={() => goToPage(page)}
                    selected={isActivePage(page)}
                    sx={{
                      py: 1.5,
                      px: 3,
                      color: isActivePage(page) ? 'primary.main' : 'text.primary',
                      fontWeight: isActivePage(page) ? 'bold' : 'normal',
                      fontSize: '0.9rem',
                      borderRadius: 2,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: '36px',
                        color: isActivePage(page) ? 'primary.main' : 'text.primary',
                      }}
                    >
                      {page.icon || <MailIcon />}
                    </ListItemIcon>
                    {page.title}
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

// const Sidebar = ({ title, pages, width = '320px' }) => {
//   return (
//     <Box
//       component="aside"
//       sx={{
//         minWidth: minWidth,
//         flexShrink: 0,
//         bgcolor: '#fff',
//         display: 'flex',
//         position: 'relative',
//       }}
//     >
//       <Box position={'sticky'} top={0} right={0} width={width}>
//         <Box p={2}>
//           <Typography variant="h" component="h2" sx={{ fontWeight: 'bold' }}>
//             {title || 'Cài đặt'}
//           </Typography>
//           <List sx={{ width: '100%' }}>
//             {pages?.length &&
//               pages.map((page) => (
//                 <ListItem key={page.title} sx={{ px: 0 }}>
//                   <ListItemButton
//                     selected
//                     sx={{
//                       py: 1.5,
//                       px: 3,
//                       color: 'primary.main',
//                       fontWeight: 'bold',
//                       fontSize: '1rem',
//                     }}
//                   >
//                     <ListItemIcon sx={{ minWidth: '36px', color: 'primary.main' }}>
//                       <MailIcon />
//                     </ListItemIcon>
//                     {page.title}
//                   </ListItemButton>
//                 </ListItem>
//               ))}
//           </List>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

export default Sidebar;

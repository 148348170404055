import { setHeadersAuthorization } from './httpRequest';
const LOCAL_STORAGE_TOKEN_KEY = process.env.REACT_APP_LOCAL_STORAGE_TOKEN_KEY;

const authToken = {
  getTokenFromLocalStorage: () => {
    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) || null;
    return token;
  },

  setTokenToLocalStorage: (token) => {
    setHeadersAuthorization();
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
  },

  removeTokenFromLocalStorage: () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
  },
};

export default authToken;

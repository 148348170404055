import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import GoogleIcon from '@mui/icons-material/Google';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, CardContent, CircularProgress } from '@mui/material';
import { useTheme } from '@emotion/react';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { login } from '../../reducers/slices/authSlice';
import { useDispatch } from 'react-redux';
import { red } from '@mui/material/colors';

const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const location = useLocation();

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Email không đúng định dạng!').required('Bạn chưa nhập email!'),
    password: Yup.string()
      .required('Bạn chưa nhập password!')
      .min(6, 'Password tối thiểu phải 6 ký tự!')
      .max(16, 'Password tối đa chỉ 16 ký tự!'),
  });

  const handleSubmit = (event) => {
    if (isLoading) {
      return;
    }
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const body = {
      email: data.get('email'),
      password: data.get('password'),
    };

    setIsLoading(true);
    authService
      .login(body)
      .then((data) => {
        if (data) {
          dispatch(login(data));
          navigate('/');
        }
      })
      .catch((error) => {
        setErrorMessage('Đăng nhập không thành công, vui lòng thử lại!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });

  const handleLoginWithGoogle = () => {
    window.open(`${process.env.REACT_APP_GG_AUTH_URL}`, '_self');
  };

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);

  //   if (queryParams.get('login_with_google')) {
  //     dispatch(login({ user: null, token: queryParams.get('login_with_google') }));
  //     window.location.href = window.location.href;
  //   }
  // }, []);

  return (
    <Container component="main" maxWidth="sm">
      <Box mt={5}>
        <Card sx={{ pb: 5 }}>
          <CardContent sx={{ p: 5 }}>
            <Typography
              textAlign={'center'}
              fontWeight={'bold'}
              color={theme.palette.primary.main}
              component="h1"
              variant="h5"
            >
              Đăng nhập
            </Typography>
            {errorMessage && (
              <Box p={3} mt={2} bgcolor={red[100]} borderRadius={1}>
                <Typography color={red[900]} align="center">
                  Đăng nhập không thành công, vui lòng thử lại!
                </Typography>
              </Box>
            )}
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <Box component={'form'} noValidate sx={{ mt: 1 }} onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
                {formik.errors.email && (
                  <FormHelperText sx={{ color: theme.palette.error.main }}>
                    {formik.errors.email}
                  </FormHelperText>
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
                {formik.errors.password && (
                  <FormHelperText sx={{ color: theme.palette.error.main }}>
                    {formik.errors.password}
                  </FormHelperText>
                )}
                <FormControlLabel
                  control={<Checkbox checked={true} value="remember" color="primary" />}
                  label="Nhớ mật khẩu"
                />
                <Button
                  disabled={!formik.isValid}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Đăng nhập
                  {isLoading && (
                    <CircularProgress
                      sx={{ ml: 1 }}
                      color="inherit"
                      size={15}
                      thickness={5}
                      variant="indeterminate"
                    ></CircularProgress>
                  )}
                </Button>
                <Button
                  onClick={handleLoginWithGoogle}
                  startIcon={<GoogleIcon />}
                  variant="outlined"
                  sx={{marginTop: "6px", marginBottom: "6px"}}
                >
                  Đăng nhập với Google
                </Button>
                <Grid container>
                  <Grid item xs>
                    {/* <Link variant="body2">Quên mật khẩu?</Link> */}
                  </Grid>
                  <Grid item>
                    <Link href="/auth/register" variant="body2">
                      {'Bạn chưa có tài khoản? Đăng ký'}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default Login;

import { Box, Button, Card, CardContent, CircularProgress, Grid } from '@mui/material';
import BotImagesItem from './BotImagesItem';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import botService from '../../services/botService';
import CardContentHeader from '../../components/cards/CardContentHeader';
import { useModal } from '../../hooks/useModal';
import BotImageUpload from './BotImageUpload';
import MainTitle from '../../components/partials/MainTitle';

const BotImagesStorage = () => {
  const { id } = useParams();
  const imageUploadModal = useModal('bot-image-upload', BotImageUpload);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadBot();
    console.log(id);
  }, [id]);

  useEffect(() => {
    console.log(images);
  }, [images]);

  const loadBot = () => {
    botService
      .getBotById(id)
      .then((res) => {
        setImages(res.images);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOpenImageUploadModal = () => {
    imageUploadModal.openModal({ botId: id });
  };

  const handleSelectImage = (image, selected) => {
    console.log(image);

    const findIndex = images.findIndex((item) => item?.file?.id === image?.file?.id);

    if (findIndex !== -1) {
      images[findIndex].selected = selected;
      setImages([...images]);
    }
  };

  const handleUpdateSelectedImages = () => {
    const imgSelectedIds = images
      .filter((image) => image.selected && image?.file?.id)
      .map((image) => image?.file.id);

    setIsLoading(true);
    botService
      .tranningImages(id, { fileIds: imgSelectedIds })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <MainTitle
        title="Thư viện ảnh"
        buttonName="Thêm ảnh"
        onClick={handleOpenImageUploadModal}
      />
      <Card variant="outlined" sx={{ height: '100%' }}>
        <CardContent>
          <CardContentHeader
            title={'Danh sách ảnh'}
            action={
              <Button onClick={handleUpdateSelectedImages} variant="contained" size="small">
                Tranning ảnh
                {isLoading && (
                  <CircularProgress
                    sx={{ ml: 1 }}
                    color="inherit"
                    size={15}
                    thickness={5}
                    variant="indeterminate"
                  ></CircularProgress>
                )}
              </Button>
            }
          />
          <Grid spacing={2} container>
            {images &&
              images.map((item, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  {item && <BotImagesItem image={item} handleSelect={handleSelectImage} />}
                </Grid>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BotImagesStorage;

import httpRequest from '../utils/httpRequest';

const authService = {
  login: (body) => {
    return httpRequest.post('/auth/login', body).then((res) => res.data);
  },

  logout: () => {
    return httpRequest.get('/auth/logout').then((res) => res.data);
  },

  register: (body) => {
    return httpRequest.post('/auth/register', body).then((res) => res.data);
  },

  getOTP: (body) => {
    return httpRequest.post('/otps/register', body).then((res) => res.data);
  },

  getUserByToken: () => {
    return httpRequest.get('/auth/token').then((res) => res.data);
  },
};

export default authService;

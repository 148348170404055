import { Field, Formik, Form } from 'formik';
import FormGroup from '../../../components/forms/FormGroup';
import Input from '../../../components/forms/Input';
import { Box, Button, CircularProgress, FormLabel } from '@mui/material';
import { useState } from 'react';
import botService from '../../../services/botService';
import { useDispatch } from 'react-redux';
import { botAction } from '../../../reducers/slices/botSlice';

const CreateNewBot = ({ onClose }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    name: '',
  };

  const createNewBot = (values) => {
    setIsLoading(true);
    botService
      .createBot(values)
      .then((res) => {
        if (res?.id) {
          botService
            .getBotById(res.id)
            .then((data) => {
              dispatch(botAction.addToCurrentBots(data));
              dispatch(botAction.setCurrentBot(data));
            })
            .catch((err) => {
              console.log(err);
            });
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = (values) => {
    createNewBot(values);
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleChange, handleBlur, values, setFieldValue }) => (
          <Form>
            <FormGroup sx={{ mb: 3 }}>
              <FormLabel sx={{ mb: 0 }} required>
                Bot Name
              </FormLabel>
              <Field
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter bot name..."
                as={Input}
              />
            </FormGroup>
            <Box width={'100%'} display={'flex'} justifyContent={'end'}>
              <Button type="submit" variant="contained" disabled={isLoading}>
                {!isLoading ? (
                  'Create'
                ) : (
                  <span>
                    Creating
                    <CircularProgress
                      sx={{ ml: 1 }}
                      color="inherit"
                      size={12}
                      thickness={5}
                      variant="indeterminate"
                    />
                  </span>
                )}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateNewBot;

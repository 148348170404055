import { Box, Button, Typography, useTheme } from '@mui/material';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

const pages = [
  {
    title: 'Lịch sử chat',
    href: '/chats',
  },
  {
    title: 'Cài đặt Bot',
    href: '/bots',
  },
  {
    title: 'Danh sách Bot',
    href: '/list-bot',
  },
];

const HeaderTabs = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const isActivePage = (page) => {
    return matchPath({ path: page.href, end: false }, location.pathname) !== null;
  };

  const goToPage = (page) => {
    navigate(page.href);
  };

  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
      <Typography
        variant="h5"
        component="a"
        noWrap
        href="/"
        sx={{
          flexGrow: 1,
          display: { xs: 'none', md: 'flex' },
          fontWeight: 400,
          letterSpacing: '.2rem',
          color: 'inherit',
          textDecoration: 'none',
          mr: 5,
        }}
      >
        <img src="/images/logo2-removebg.png" style={{ width: '60px' }} />
      </Typography>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        {pages.map((page) => {
          return (
            <Button
              key={page.title}
              onClick={() => goToPage(page)}
              variant="text"
              sx={{
                fontSize: '0.75rem',
                m: 1,
                px: 1.5,
                py: 1,
                color: 'white',
                backgroundColor: isActivePage(page) ? theme.palette.primary.dark : '',
                '&:hover': {
                  backgroundColor: isActivePage(page)
                    ? theme.palette.primary.dark
                    : theme.palette.primary.light,
                },
              }}
            >
              {page.title}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default HeaderTabs;

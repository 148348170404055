import { Box, Typography } from '@mui/material';
import SearchInput from '../../../components/forms/SearchInput';

const ChatConversationMenuHeader = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} px={3} py={2.5}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2.5}>
        <Typography variant="h6" color={'primary.main'}>
          Danh sách chat
        </Typography>
      </Box>
      <SearchInput placeholder={'Nhập tên hoặc số điện thoại...'} />
    </Box>
  );
};

export default ChatConversationMenuHeader;

import { Box, Typography, Button } from '@mui/material';
import * as React from 'react';

const MainTitle = ({ title, buttonName, buttonIcon, titleTag = 'h5', onClick }) => {
  return (
    <Box
      width={'100%'}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems="center"
      mb={3}
    >
      <Typography variant={titleTag} component={titleTag}>
        {title}
      </Typography>
      {buttonName && (
        <Button variant="contained" onClick={onClick} startIcon={buttonIcon}>
          {buttonName}
        </Button>
      )}
    </Box>
  );
};

export default MainTitle;

import { Box, Grid } from '@mui/material';
import BotSocialConnectListItem from './BotSocialConnectListItem';

const BotSocialConnectList = ({ data, setSocial }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        {data?.info?.map((el, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            key={i}
            sx={{
              '&.MuiGrid-root.MuiGrid-item': { padding: 0, margin: '16px 0 0 16px' },
              border: '1px solid #ccc',
              borderRadius: '10px',
            }}
          >
            <BotSocialConnectListItem socialId={data.id} data={el} setSocial={setSocial} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BotSocialConnectList;

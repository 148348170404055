import { Avatar, Box, Card, CardContent, Divider, Typography, useTheme } from '@mui/material';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import AccountInformationMenu from './AccountInformationMenu';

const AccountInformation = () => {
  const theme = useTheme();
  const authSelector = useSelector((state) => state.auth);
  const [userLogged, setUserLogged] = useState(authSelector.user);
  const user = useSelector((state) => state.auth.user);
  // console.log(user);
  return (
    <Card>
      <CardContent>
        <CardContentHeader
          title={'Thông tin cá nhân'}
          subtitle={'Cài đặt thông tin cá nhân, gói cước'}
        />
        <Box display={'flex'} flexDirection={'row'} mt={3}>
          <Avatar
            sx={{ width: 100, height: 100, border: `3px solid ${theme.palette.grey[300]}` }}
          ></Avatar>
          <Box ml={3} display={'flex'} flexDirection={'column'}>
            <Typography variant="h5" fontWeight={500} sx={{ my: 1 }}>
              {userLogged.name}
            </Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mb={0.25}>
              <EmailOutlinedIcon sx={{ mr: 1, fontSize: 15, color: 'text.secondary' }} />
              <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'normal' }}>
                {userLogged.email}
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              <PhoneOutlinedIcon sx={{ mr: 1, fontSize: 15, color: 'text.secondary' }} />
              <Typography variant="body1" color="text.secondary" sx={{ fontWeight: 'normal' }}>
                {userLogged.phone}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 3 }} />
        <AccountInformationMenu />
      </CardContent>
    </Card>
  );
};

export default AccountInformation;

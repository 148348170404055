import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import { useDispatch } from 'react-redux';
import { setCurrentSocialConnect } from '../../../reducers/slices/socialConnectSlice';
import facebookService from '../../../services/facebookService';
import { useState } from 'react';
import ConfirmDialog from '../../../hooks/confirmDialog';

const BotSocialConnectListItem = ({ socialId, data, setSocial }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const handleCloseDialog = (event) => {
    event.stopPropagation();
    setOpenDialog(false);
  };

  const handleSelect = () => {
    dispatch(setCurrentSocialConnect(data));
  };

  const handleGoToPage = (event) => {
    event.stopPropagation();
    window.open(`https://facebook.com/${data.pageID}`, '_blank', 'noopener,noreferrer');
  };

  const handleUnsubscribeApp = async (event) => {
    event.stopPropagation();
    setOpenDialog(false);
    const updatedSocial = await facebookService.unsubscribeApp(socialId, data.pageID);
    setSocial(updatedSocial);
  };

  const handleSubscribeApp = async (event) => {
    event.stopPropagation();
    setOpenDialog(false);
    const body = { id: socialId, pageID: data.pageID };
    const updatedSocial = await facebookService.subscribeApp(body);
    setSocial(updatedSocial);
  };

  return (
    <Box onClick={handleSelect}>
      <Card
        variant="outlined"
        sx={{
          '&:hover': { backgroundColor: theme.palette.action.hover },
          cursor: 'pointer',
          border: 0,
        }}
      >
        <CardContent sx={{ '&.MuiCardContent-root': { p: 2 } }}>
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar src={data.avatar} variant="rounded" sx={{ width: 54, height: 54 }} />
              <Typography sx={{ ml: 1.5 }} variant="body1" fontWeight={500} fontSize={16}>
                {data.pageName}
                <Typography variant="subtitle2">{data.pageID}</Typography>
              </Typography>
            </Box>
            <Avatar
              src="https://cdn.jim-nielsen.com/ios/512/facebook-2019-05-21.png?rf=1024"
              variant="rounded"
              sx={{ width: 24, height: 24 }}
            />
            {/* <IconButton aria-label="go-to-page" onClick={handleGoToPage}>
            <LinkOffIcon />
          </IconButton>
          <IconButton aria-label="go-to-page" onClick={handleGoToPage}>
            <OpenInNewIcon />
          </IconButton> */}
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          {data.isSubscribed ? (
            <Tooltip title="Huỷ kết nối Bot" arrow>
              <IconButton color="warning" onClick={handleOpenDialog}>
                <LinkOffIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Bật kết nối Bot" arrow>
              <IconButton color="warning" onClick={handleOpenDialog}>
                <PowerOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Cài đặt" arrow>
            <IconButton color="info" onClick={() => {}}>
              <SettingsOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mở Trang" arrow>
            <IconButton color="default" onClick={handleGoToPage}>
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        </CardActions>
      </Card>
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={data.isSubscribed ? handleUnsubscribeApp : handleSubscribeApp}
        title={data.isSubscribed ? 'Huỷ kết nối Bot' : 'Bật kết nối Bot'}
        subtitle={'OK'}
        content={
          data.isSubscribed
            ? `Bạn có thực sự muốn huỷ kết nối Bot cho Trang này?`
            : `Bạn có thực sự muốn bật kết nối Bot cho Trang này?`
        }
      />
    </Box>
  );
};

export default BotSocialConnectListItem;

import { Box, Grid, useTheme } from '@mui/material';
import MainTitle from '../../../components/partials/MainTitle';
import BotInformation from './BotInformation';
import BotFileTranning from './BotFileTranning';
import BotUrlTranning from './BotUrlTranning';

const BotSetting = () => {
  const theme = useTheme();

  return (
    <Box>
      <MainTitle title="Cài đặt Bot" />
      <Grid container spacing={3} sx={{ height: '100%' }}>
        <Grid item xl={6} lg={6} md={12} sm={12}>
          <BotInformation />
        </Grid>
        <Grid item xl={6} lg={6} md={12} sm={12}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box sx={{ mb: 3 }}>
              <BotFileTranning />
            </Box>
            <Box>
              <BotUrlTranning />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BotSetting;

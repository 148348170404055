import {
  Avatar,
  Box,
  Button,
  CardActions,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentSocialConnect } from '../../../reducers/slices/socialConnectSlice';
import { useState } from 'react';
import facebookService from '../../../services/facebookService';

const BotSocialConnectDetail = ({ socialId, setSocial }) => {
  const { currentSocialConnect } = useSelector((state) => state.socialConnect);
  const { currentBots } = useSelector((state) => state.bot);
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(setCurrentSocialConnect(null));
  };

  const [selectedBot, setSelectedBot] = useState(currentSocialConnect.botId);

  const handleChange = (event) => {
    setSelectedBot(event.target.value);
  };

  const handleEnableBot = async (event) => {
    const body = { pageID: currentSocialConnect.pageID, botId: selectedBot };
    const data = await facebookService.updateBot(socialId, body);

    setSocial(data);
    dispatch(setCurrentSocialConnect(null));
  };

  const handleGoToPage = (event) => {
    window.open(
      `https://facebook.com/${currentSocialConnect.pageID}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'} mb={2}>
        <ArrowBackIcon onClick={handleBack} sx={{ cursor: 'pointer', mr: 1.5, fontSize: 18 }} />
        <Typography variant="h7" fontWeight={'bold'}>
          Chi tiết
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CardContentHeader title={'Thông tin Trang'} titleSize={14} />
            <Box>
              <Avatar
                src={currentSocialConnect.avatar}
                sx={{ width: 100, height: 100 }}
                variant="rounded"
              />
              <Box display={'flex'} alignItems={'center'} mt={2}>
                <Typography variant="h7" fontSize={15} fontWeight={'bold'}>
                  Kiểu kết nối:
                </Typography>
                <Typography variant="body2" fontSize={15} ml={1}>
                  Facebook
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} mt={1}>
                <Typography variant="h7" fontSize={15} fontWeight={'bold'}>
                  ID:
                </Typography>
                <Typography variant="body2" fontSize={15} ml={1}>
                  {currentSocialConnect.pageID}
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} mt={1}>
                <Typography variant="h7" fontSize={15} fontWeight={'bold'}>
                  Tên Trang:
                </Typography>
                <Typography variant="body2" fontSize={15} ml={1}>
                  {currentSocialConnect.pageName}
                </Typography>
              </Box>
            </Box>
            <Button
              size="small"
              variant="outlined"
              endIcon={<OpenInNewIcon />}
              sx={{ marginTop: 2 }}
              onClick={handleGoToPage}
            >
              Mở Trang
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <CardContentHeader title={'Cài đặt'} titleSize={14} />
            <Box>
              <Formik>
                <Form>
                  <FormGroup sx={{ mb: 3 }}>
                    <FormLabel required>Chọn Bot</FormLabel>
                    <Select value={selectedBot} onChange={handleChange}>
                      {currentBots.map((bot) => (
                        <MenuItem value={bot.id}>{bot.name}</MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </Form>
              </Formik>
            </Box>
            <CardActions>
              <Button
                variant="contained"
                startIcon={<SaveOutlinedIcon />}
                onClick={handleEnableBot}
              >
                Lưu
              </Button>
            </CardActions>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BotSocialConnectDetail;

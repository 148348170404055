import httpRequest from '../utils/httpRequest';

const facebookService = {
  getPagesByUserId: () => {
    return httpRequest.get('/users/facebook/page').then((res) => res.data);
  },

  updateBot: (id, body) => {
    return httpRequest.put(`/users/facebook/update-bot/${id}`, body).then((res) => res.data);
  },

  unsubscribeApp: (id, pageID) => {
    return httpRequest
      .delete(`/users/facebook/subscribed_apps/${id}/${pageID}`)
      .then((res) => res.data);
  },

  subscribeApp: (body) => {
    return httpRequest.post(`/users/facebook/subscribed_apps`, body).then((res) => res.data);
  },
};

export default facebookService;

import { Box, Card, CardContent, Typography } from '@mui/material';
import MainTitle from '../../../components/partials/MainTitle';
import CardContentHeader from '../../../components/cards/CardContentHeader';
import BotSocialConnectList from './BotSocialConnectList';
import { useModal } from '../../../hooks/useModal';
import BotSocialConnectCreateModal from './BotSocialConnectCreateModal';
import BotSocialConnectDetail from './BotSocialConnectDetail';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import facebookService from '../../../services/facebookService';
import FacebookIcon from '@mui/icons-material/Facebook';

const BotSocialConnect = () => {
  const [social, setSocial] = useState();
  // const createConnectModal = useModal('create-connect', BotSocialConnectCreateModal, {
  //   configs: {
  //     size: 'lg',
  //   },
  // });
  const { currentSocialConnect } = useSelector((state) => state.socialConnect);
  const userInfo = useSelector((state) => state.auth.user);
  const botInfo = useSelector((state) => state.bot.currentBot);

  const [status, setStatus] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setStatus(queryParams.get('success'));
  }, []);

  // const openConnectModal = () => {
  //   createConnectModal.openModal();
  // };

  const handleLoginWithFacebook = () => {
    const reqQuery = {
      userId: userInfo.id,
      botId: botInfo.id,
    };

    const queryString = new URLSearchParams(reqQuery).toString();

    const url = `${process.env.REACT_APP_FB_AUTH_URL}?${queryString}`;

    window.open(url, '_self');
  };

  useEffect(() => {
    (async () => {
      const data = await facebookService.getPagesByUserId();
      setSocial(data);
    })();
  }, []);

  return (
    <Box>
      <MainTitle
        title={'Kết nối Facebook'}
        buttonName={'Đăng nhập với Facebook'}
        buttonIcon={<FacebookIcon />}
        onClick={handleLoginWithFacebook}
      />
      <Card>
        <CardContent>
          {currentSocialConnect ? (
            <BotSocialConnectDetail socialId={social?.id} setSocial={setSocial} />
          ) : (
            <>
              <CardContentHeader title={'Danh sách Trang'} />
              {status && (
                <Alert
                  icon={<CheckIcon fontSize="inherit" sx={{ marginTop: '5px' }} />}
                  severity="success"
                  sx={{ marginBottom: '20px', backgroundColor: '#B7D78D' }}
                >
                  <Typography color="#587E49" variant="h5" marginBottom="10px">
                    Chúc mừng!
                  </Typography>
                  <Typography>Tài khoản của bạn đã được kết nối thành công.</Typography>
                  <Typography>
                    Bất cứ khi nào bạn cần làm mới acccess token hoặc đồng bộ hóa dữ liệu mới, chỉ cần đăng nhập lại với Facebook.
                  </Typography>
                </Alert>
              )}
              {social ? (
                <BotSocialConnectList data={social} setSocial={setSocial} />
              ) : (
                <Typography padding={10} textAlign="center" color="#777777">
                  Chưa có Trang nào được kết nối
                </Typography>
              )}
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default BotSocialConnect;
